import { useAuthenticationInfo } from '@src/common/state/volatile/hooks';
import { useGetAllUsersQuery, } from '../api';
export const useInternalDefaultPage = () => {
	const { user } = useAuthenticationInfo();
	return { permissions: user.internalPermissions ?? [] };
};


// Hook for support page
export const useSupportPage = () => {
    // Get user authentication information
    const { user } = useAuthenticationInfo();

    // Fetch all users using API query hook
    const { data: users, refetch, isLoading, isError } = useGetAllUsersQuery();

    // Log users for debugging purposes (you can remove this later)
    console.log(users);

    // Return permissions, users, refetch function for updating, and query status indicators
    return { 
        permissions: user.internalPermissions ?? [],  // User permissions
        users: users ?? [],                            // List of users or empty array if undefined
        refetch,                                       // Function to refetch the users if needed
        isLoading,                                     // Loading status
        isError                                        // Error status
    };
};