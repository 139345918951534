import { useMemo, useState, useRef } from 'react';
import useUnmount from 'react-use/esm/useUnmount';

import { copyToClipboard } from '@src/common/helpers/dom';
import { sleep } from '@src/common/helpers/timing';

export const useCopyToClipboard = (
	textToCopy: string,
	minDelay: number = 2000
): {
	isCopying: boolean;
	isError: boolean;
	copyStatus: string;
	handleCopy: () => void;
} => {
	const [isCopying, setIsCopying] = useState(false);
	const [isError, setIsError] = useState(false);
	const [copyStatus, setCopyStatus] = useState('');

	const timerRef = useRef();
	useUnmount(() => timerRef.current && clearTimeout(timerRef.current));
	return useMemo(
		() => ({
			isCopying,
			isError,
			copyStatus,
			handleCopy: async () => {
				const { sleepPromise, timerId } = sleep(minDelay);
				timerRef.current = timerId;
				try {
					setIsCopying(true);
					isError && setIsError(false);
					setCopyStatus('Copied'!);
					await Promise.all([sleepPromise, copyToClipboard(textToCopy)]);
					setIsCopying(false);
				} catch (error) {
					setCopyStatus('Failed to copy!');
					setIsError(true);
					setIsCopying(false);
					console.error('copyToClipboard:error', error);
				}
			},
		}),
		[isCopying, textToCopy, minDelay, isError, copyStatus]
	);
};
