import { css } from '@emotion/react';
import { useMemo } from 'react';
import { useTheme } from '@mui/material';

export const useAdminTableStyles = () => {
	const theme = useTheme();

	return useMemo(() => {
		const tableContainer = css({
			width: '100%',
			border: `1px solid ${theme.colors.borderColor.main}`,
			borderRadius: theme.spacing(0.5),
            overflow: 'hidden',
		});

		const tableRow = css({
			border: 0,
			borderBottom: `1px solid ${theme.colors.borderColor.main}`,
			display: 'grid',
            justifyContent: 'space-between',
			gridTemplateColumns: '200px auto 70px',
		});

		return {
			tableRow,
			tableContainer,
		};
	}, [theme]);
};

export const useUsersTableRowStyles = () => {
	return useMemo(() => {
		const hideScrollbar = css`
			&::-webkit-scrollbar {
				display: none;
			}
		`;

		const tableCell = css(hideScrollbar, {
			border: 0,
			alignContent: 'center',
			overflow: 'auto',
            display: 'flex',
		});

		return {
			tableCell,
		};
	}, []);
};
