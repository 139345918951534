import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { useMemo } from 'react';

export const useTestReportStyles = ({ isLoading }) => {
	const theme = useTheme();
	return useMemo(
		() => ({
			section: css({
				display: 'flex',
				flexDirection: 'column',
				padding: `${theme.spacing(4)} 0`,
				height: '100%',
				width: '100%',
			}),
			backToTestRunsButton: css({
				padding: 0,
				margin: `${theme.spacing(2)} 0`,
				gap: '5px',
			}),
			loading: css({
				paddingTop: theme.spacing(2),
				marginLeft: theme.spacing(2),
			}),
			reportDiv: css({
				width: '100%',
				height: '100%',

				opacity: isLoading ? 0 : 1,
				transition: 'opacity 0.5s',

				'.htmlreport': {
					padding: 0,
				},
				'.chip:has( > div[title=Screenshots])': {
					display: 'none',
				},
				'.chip:has( > div[title=Attachments])': {
					display: 'none',
				},
			}),
		}),
		[theme, isLoading]
	);
};
