import api from '@src/api';
import { TestSuiteRun } from '../types';
import { transformAPIGetTestRunsResponse } from '@src/modules/test-runs/api/transformers';

export const testRunsAPI = api
	.enhanceEndpoints({ addTagTypes: ['TestRuns'] })
	.injectEndpoints({
		endpoints: (builder) => ({
			// [getTestRuns]
			getTestRuns: builder.query<TestSuiteRun[], void>({
				query: () => ({
					url: `test-runs`,
				}),
				providesTags: ['TestRuns'],
				transformResponse: transformAPIGetTestRunsResponse,
			}),
			// [getTestRunReportURL]
			getTestRunReportURL: builder.query<string, { id: string }>({
				query: ({ id }) => ({
					url: `test-runs/${id}/report-url`,
				}),
			}),
			// [getTraceURL]
			getTraceURL: builder.query<string, { id: string; testId: string }>({
				query: ({ id, testId }) => ({
					url: `test-runs/${id}/${testId}/trace-url`,
				}),
			}),
		}),
	});

export const {
	useGetTestRunsQuery,
	useGetTestRunReportURLQuery,
	useLazyGetTraceURLQuery,
} = testRunsAPI;
