import {
	Alert,
	Box,
	Snackbar,
	TextField,
	IconButton,
	InputAdornment,
	Button,
} from '@mui/material';
import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useCreateSupportUserMutation } from '../api';
import { UsersTable } from './components/UsersTable';
import { useSupportPage } from './hooks';

// Main SupportPage Component
export const SupportPage: React.FC = () => {
	const { users, refetch } = useSupportPage();
	const [createSupportUser] = useCreateSupportUserMutation();
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [email, setEmail] = useState('');
	const [isAddingUser, setIsAddingUser] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState('');
	const [searchQuery, setSearchQuery] = useState('');

	// Function to handle adding a new user
	const handleAddUserClick = () => {
		setIsAddingUser(true);
	};

	// Function to handle canceling the add user action
	const handleCancelAddUser = () => {
		setIsAddingUser(false);
		setEmail(''); // Clear email input when canceled
	};

	// Function to handle creating the user
	const handleCreateUser = async () => {
		if (email) {
			try {
				await createSupportUser({ email }).unwrap();
				handleShowSnackbar('User created successfully!');
				setEmail(''); // Clear email input
				setIsAddingUser(false); // Close floating input
				refetch(); // Update the table after user creation
			} catch (error) {
				handleShowSnackbar('Failed to create user!');
			}
		}
	};

	// Function to show the snackbar
	const handleShowSnackbar = (message: string) => {
		setSnackbarMessage(message);
		setSnackbarOpen(true);
	};

	// Function to close the snackbar
	const handleCloseSnackbar = () => {
		setSnackbarOpen(false);
	};

	// Filter users based on search query
	const filteredUsers = users.filter((user) =>
		user.email.toLowerCase().includes(searchQuery.toLowerCase())
	);

	return (
		<Box>
			{/* Add user input and button */}
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'flex-end',
					margin: '10px',
					alignItems: 'center',
					transition: 'width 0.3s ease', // Smooth transition
					width: isAddingUser ? '100%' : 'auto',
				}}
			>
				<Choose>
					<When condition={isAddingUser}>
						<Button
							startIcon={<AddIcon />}
							variant="contained"
							onClick={handleAddUserClick}
							sx={{
								transition: 'width 0.3s ease',
							}}
						>
							Add User
						</Button>
					</When>
					<Otherwise>
						<Box
							sx={{
								display: 'flex',
								transition: 'all 0.3s ease',
							}}
						>
							<TextField
								label="Enter user email"
								variant="outlined"
								fullWidth
								margin="dense"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton onClick={handleCancelAddUser}>
												<CloseIcon />
											</IconButton>
											<IconButton onClick={handleCreateUser}>
												<CheckIcon />
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
						</Box>
					</Otherwise>
				</Choose>
			</Box>

			{/* Search input */}
			<TextField
				label="Search Users"
				variant="outlined"
				fullWidth
				margin="dense"
				value={searchQuery}
				onChange={(e) => setSearchQuery(e.target.value)} // Update search query
			/>

			<Snackbar
				open={snackbarOpen}
				autoHideDuration={3000}
				onClose={handleCloseSnackbar}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			>
				<Alert onClose={handleCloseSnackbar} severity="success">
					{snackbarMessage}
				</Alert>
			</Snackbar>

			{/* Pass filtered users to UsersTable */}
			<UsersTable users={filteredUsers} onShowMessage={handleShowSnackbar} />
		</Box>
	);
};
