import { useCallback, useEffect, useMemo, useState } from 'react';
import { UserStory, UserStoryStatus } from '@src/modules/user-stories/service';
import { InboxCollectionAndStoriesBadgesData } from '@src/modules/collections/service';
import { useSearchParams } from 'react-router-dom';

const PAGINATION_ITEMS_PER_PAGE = 15;

export const useStoriesFilter = ({
	storiesForActiveCollection,
	userStoriesBadgesInfo,
}: {
	storiesForActiveCollection: UserStory[];
	userStoriesBadgesInfo: InboxCollectionAndStoriesBadgesData['userStoriesBadgesInfo'];
}) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const paginationPage = parseInt(searchParams.get('page')) || 1;

	const [activeFilterValue, setActiveFilterValue] =
		useState<UserStoryStatus>(null);

	//setActiveSearchTerm
	const [activeSearchTerm, setActiveSearchTerm] = useState<string>(undefined);

	const filteredStories = useMemo(() => {
		if (!storiesForActiveCollection) {
			return;
		}

		// split the search term into words and filter the stories by them
		// each word has to appear in the story title
		const splitSearchTerms = activeSearchTerm?.toLowerCase()?.split(' ');

		const { newStories, rest } = storiesForActiveCollection.reduce(
			(acc, story) => {
				const withinFilter =
					!activeFilterValue || story.status === activeFilterValue;

				const withinSearch =
					!activeSearchTerm ||
					splitSearchTerms.every((word) =>
						story.title.toLowerCase().includes(word)
					);

				if (!(withinFilter && withinSearch)) {
					return acc;
				}

				if (userStoriesBadgesInfo[story.id]) {
					acc.newStories.push(story);
				} else {
					acc.rest.push(story);
				}

				return acc;
			},
			{ newStories: [], rest: [] } as {
				newStories: UserStory[];
				rest: UserStory[];
			}
		);

		return [
			...newStories.sort(
				(a, b) =>
					new Date(b.recentActivityDate).getTime() -
					new Date(a.recentActivityDate).getTime()
			),
			...rest,
		];
	}, [
		activeFilterValue,
		activeSearchTerm,
		storiesForActiveCollection,
		userStoriesBadgesInfo,
	]);

	const hasFilteredOutStories =
		storiesForActiveCollection &&
		filteredStories &&
		storiesForActiveCollection.length > filteredStories.length;

	const onStoriesPaginationChange = useCallback(
		(event, value: number) => {
			setSearchParams((prevSearchParams) => ({
				...prevSearchParams,
				page: value,
			}));
		},
		[setSearchParams]
	);

	const filteredStoriesPerPage = useMemo(() => {
		if (!filteredStories) {
			return [];
		}

		return filteredStories.slice(
			(paginationPage - 1) * PAGINATION_ITEMS_PER_PAGE,
			paginationPage * PAGINATION_ITEMS_PER_PAGE
		);
	}, [filteredStories, paginationPage]);

	const storiesPaginationProps = useMemo(() => {
		return {
			onChange: onStoriesPaginationChange,
			count:
				Math.ceil((filteredStories?.length ?? 1) / PAGINATION_ITEMS_PER_PAGE) ||
				1,
			page: paginationPage,
			color: 'primary' as 'primary' | 'secondary' | 'standard',
		};
	}, [onStoriesPaginationChange, filteredStories, paginationPage]);

	useEffect(() => {
		// once the filtering is adjust move to the least page possible
		if (storiesPaginationProps.count < paginationPage) {
			setSearchParams((prevSearchParams) => ({
				...prevSearchParams,
				page: 1,
			}));
		}
	}, [storiesPaginationProps, setSearchParams, paginationPage]);

	return {
		activeFilterValue,
		setActiveFilterValue,
		setActiveSearchTerm,
		filteredStories,
		filteredStoriesPerPage,
		hasFilteredOutStories,
		storiesPaginationProps,
	};
};
