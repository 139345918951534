import { useStoriesFilter } from './useStoriesFilter';
import {
	useGetAllUserStoriesQuery,
	useGetUserStoriesQuery,
	UserStory,
} from '@src/modules/user-stories/service';
import { useActiveStory } from './useActiveStory';
import { useMemo } from 'react';
import {
	INBOX_COLLECTION_ID,
	InboxCollectionAndStoriesBadgesData,
	useInboxCollectionAndStories,
	UserStoriesCollection,
	ALL_FLOWS_COLLECTION_ID,
} from '@src/modules/collections/service';

export const useStories = ({
	activeCollection,
	userStoriesBadgesInfo,
}: {
	activeCollection?: UserStoriesCollection;
	userStoriesBadgesInfo: InboxCollectionAndStoriesBadgesData['userStoriesBadgesInfo'];
}) => {
	const {
		storiesForActiveCollection,
		isLoading: isLoadingStories,
		isFetching: isFetchingStories,
		isError: isErrorLoadingStories,
		currentData: currentStoriesForActiveCollection,
	} = useStoriesForActiveCollection(activeCollection?.id);

	const {
		activeFilterValue,
		setActiveFilterValue,
		setActiveSearchTerm,
		filteredStories,
		hasFilteredOutStories,
		storiesPaginationProps,
		filteredStoriesPerPage,
	} = useStoriesFilter({ storiesForActiveCollection, userStoriesBadgesInfo });

	const {
		activeStory,
		handleCloseStory,
		handleCreateNewStory,
		handleNewStoryCreatedSuccessfully,
	} = useActiveStory({
		stories: filteredStories,
		activeCollection,
	});

	return {
		storiesForActiveCollection,
		isLoadingStories,
		isErrorLoadingStories,
		activeFilterValue,
		setActiveFilterValue,
		setActiveSearchTerm,
		filteredStories,
		hasFilteredOutStories,
		activeStory,
		handleCloseStory,
		handleCreateNewStory,
		handleNewStoryCreatedSuccessfully,
		storiesPaginationProps,
		filteredStoriesPerPage,
		isFetchingNewStories:
			isFetchingStories && !currentStoriesForActiveCollection,
	};
};

// const pollingInterval = process.env.REACT_APP_ENV === 'local' ? 30_000 : 5000;

const useStoriesForActiveCollection = (activeCollectionId?: string) => {
	const { isInboxActive, isAllFlowsActive } = useMemo(
		() => ({
			isInboxActive: activeCollectionId === INBOX_COLLECTION_ID,
			isAllFlowsActive: activeCollectionId === ALL_FLOWS_COLLECTION_ID,
		}),
		[activeCollectionId]
	);

	const {
		data: stories,
		isLoading: isLoadingStories,
		isFetching: isFetchingStories,
		isError: isErrorLoadingStories,
		currentData: currentStoriesForActiveCollection,
	} = useGetUserStoriesQuery(
		{ collectionId: activeCollectionId },
		{
			skip: !activeCollectionId || isInboxActive || isAllFlowsActive,
			pollingInterval: 5000,
		}
	);

	const {
		data: allStories,
		isLoading: isAllStoriesLoading,
		isFetching: isAllStoriesFetching,
		isError: isAllStoriesErrorLoading,
		currentData: currentAllStories,
	} = useGetAllUserStoriesQuery(undefined, { skip: !isAllFlowsActive });

	const {
		inboxUserStories,
		isLoading: isInboxUserStoriesLoading,
		isFetching: isFetchingInboxStories,
		isError: isErrorLoadingInboxStories,
		currentData: currentStoriesForInbox,
	} = useInboxCollectionAndStories();

	// return already loaded data
	const storiesForActiveCollection: UserStory[] = useMemo(() => {
		if (!activeCollectionId) {
			return [];
		}

		if (isInboxActive && !isInboxUserStoriesLoading) {
			return inboxUserStories;
		}

		if (isAllFlowsActive && !isAllStoriesLoading) {
			return allStories;
		}

		return stories;
	}, [
		isAllFlowsActive,
		allStories,
		isAllStoriesLoading,
		isInboxActive,
		activeCollectionId,
		stories,
		inboxUserStories,
		isInboxUserStoriesLoading,
	]);

	// return already data that is fetching on collection switch
	const currentData = useMemo(() => {
		if (isInboxActive) {
			return currentStoriesForInbox;
		}

		if (isAllFlowsActive) {
			return currentAllStories;
		}

		return currentStoriesForActiveCollection;
	}, [
		isInboxActive,
		isAllFlowsActive,
		currentAllStories,
		currentStoriesForActiveCollection,
		currentStoriesForInbox,
	]);

	return {
		storiesForActiveCollection,
		isLoading:
			isInboxUserStoriesLoading || isLoadingStories || isAllStoriesLoading,
		isFetching:
			isFetchingStories || isFetchingInboxStories || isAllStoriesFetching,
		isError:
			isErrorLoadingStories ||
			isErrorLoadingInboxStories ||
			isAllStoriesErrorLoading,
		currentData,
	};
};
